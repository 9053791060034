<template>
  <span>
    <svg
      :fill="iconFill"
      :stroke="iconStroke"
      :width="width"
      :height="height"
      :viewBox="`0 0 ${width} ${height}`"
      :class="{ 'animate-spin-slow': spin }"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g v-if="iconName === 'search'">
        <path
          d="M16.7 15.3C17.6 14.1 18.1 12.7 18.1 11.1C18.1 7.2 15 4 11.1 4C7.2 4 4 7.2 4 11.1C4 15 7.2 18.2 11.1 18.2C12.7 18.2 14.2 17.7 15.3 16.8L18.3 19.8C18.5 20 18.8 20.1 19 20.1C19.2 20.1 19.5 20 19.7 19.8C20.1 19.4 20.1 18.8 19.7 18.4L16.7 15.3ZM11.1 16.1C8.3 16.1 6 13.9 6 11.1C6 8.3 8.3 6 11.1 6C13.9 6 16.2 8.3 16.2 11.1C16.2 13.9 13.9 16.1 11.1 16.1Z"
        />
      </g>
      <g v-else-if="iconName === 'gear'">
        <path
          d="M17.3 9.2l1.1-2.1L17 5.7l-2.1 1.1c-.3-.2-.7-.3-1.1-.4L13 4h-2l-.8 2.3c-.3.1-.7.2-1 .4L7.1 5.6 5.6 7.1l1.1 2.1c-.2.3-.3.7-.4 1L4 11v2l2.3.8c.1.4.3.7.4 1.1L5.6 17 7 18.4l2.1-1.1c.3.2.7.3 1.1.4L11 20h2l.8-2.3c.4-.1.7-.3 1.1-.4l2.1 1.1 1.4-1.4-1.1-2.1c.2-.3.3-.7.4-1.1L20 13v-2l-2.3-.8c-.1-.3-.2-.7-.4-1zM12 15c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
        />
      </g>
      <g v-else-if="iconName === 'trash'">
        <path
          d="M6 10v8c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-8H6zm10-3V5c0-.6-.4-1-1-1H9c-.6 0-1 .4-1 1v2H4v2h16V7h-4zm-2 0h-4V6h4v1z"
        />
      </g>
      <g v-else-if="iconName === 'present'">
        <path
          d="M19 8h-1.2c.1-.3.2-.6.2-1 0-1.7-1.3-3-3-3-1.4 0-2.4.8-3 1.7-.6-.9-1.6-1.7-3-1.7-1.7 0-3 1.3-3 3 0 .4.1.7.2 1H5a1 1 0 00-1 1v3a1 1 0 001 1h1v6a1 1 0 001 1h10a1 1 0 001-1v-6h1a1 1 0 001-1V9a1 1 0 00-1-1zm-4-2c.6 0 1 .4 1 1s-.4 1-1 1h-1.8c.2-.9.7-2 1.8-2zM8 7c0-.6.4-1 1-1 1.1 0 1.6 1.1 1.8 2H9c-.6 0-1-.4-1-1zm0 6h3v5H8v-5zm8 5h-3v-5h3v5zm2-7H6v-1h12v1z"
        />
      </g>
      <g v-else-if="iconName === 'skull'">
        <path
          d="M12 4c-3.9 0-7 3.1-7 7 0 2.4 1.2 4.5 3 5.7V19c0 .6.4 1 1 1h1v-2h1v2h2v-2h1v2h1c.6 0 1-.4 1-1v-2.3c1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7zm-2.5 9c-.8 0-1.5-.7-1.5-1.5S8.7 10 9.5 10s1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm5 0c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5z"
        />
      </g>
      <g v-else-if="iconName === 'tweet'">
        <path
          d="M20 7c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H4c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C19 8.3 19.6 7.7 20 7z"
        />
      </g>
      <g v-else-if="iconName === 'retweet'">
        <path
          d="M16 8v7.6l-1.3-1.3c-.4-.4-1-.4-1.4 0-.2.2-.3.4-.3.7 0 .3.1.5.3.7l3 3c.4.4 1 .4 1.4 0l3-3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L18 15.6V7c0-.6-.4-1-1-1h-5v2h4zM8 16V8.4l1.3 1.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-3-3c-.4-.4-1-.4-1.4 0l-3 3c-.2.2-.3.4-.3.7 0 .3.1.5.3.7.4.4 1 .4 1.4 0L6 8.4V17c0 .6.4 1 1 1h5v-2H8z"
        />
      </g>
      <g v-else-if="iconName === 'like'">
        <path
          d="M15.15 6c-1.313 0-2.45.68-3.15 1.7C11.3 6.68 10.162 6 8.85 6 6.75 6 5 7.7 5 9.739 5 13.477 10.25 19 12 19s7-5.523 7-9.261C19 7.699 17.25 6 15.15 6z"
        />
      </g>
      <g v-else-if="iconName === 'archive-like'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11 6.684V11.6l-1.3-1.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l3 3c.4.4 1 .4 1.4 0l3-3c.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.4-.4-1-.4-1.4 0L13 11.6V6.684A3.715 3.715 0 0115.15 6C17.25 6 19 7.7 19 9.739 19 13.477 13.75 19 12 19s-7-5.523-7-9.261C5 7.699 6.75 6 8.85 6c.802 0 1.539.254 2.15.684z"
        />
      </g>
      <g v-else-if="iconName === 'reply'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13 13v3.04c1.064-.818 2.126-1.701 3.015-2.622C17.415 11.968 18 10.815 18 10a3 3 0 00-3-3H9a3 3 0 000 6h4zm-4 2h2v3c0 .824.948 1.306 1.614.821C15.672 16.595 20 13.284 20 10a5 5 0 00-5-5H9a5 5 0 000 10z"
        />
      </g>
      <g v-else-if="iconName === 'external-link'">
        <path
          d="M17 17H7V7H10V5H7C5.89 5 5 5.9 5 7V17C5 18.1 5.89 19 7 19H17C18.1 19 19 18.1 19 17V14H17V17ZM13 5C12.4477 5 12 5.44772 12 6C12 6.55228 12.4477 7 13 7H15.59L9.76 12.83L11.17 14.24L17 8.41V11C17 11.5523 17.4477 12 18 12C18.5523 12 19 11.5523 19 11V6C19 5.44772 18.5523 5 18 5H13Z"
        />
      </g>
      <g v-else-if="iconName === 'external-link-alt'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.17601 11.8335C4.28851 11.9459 4.44101 12.009 4.60001 12.009C4.75901 12.009 4.91151 11.9459 5.02401 11.8335L10.8 6.05752V10.6095C10.8 10.7687 10.8632 10.9213 10.9757 11.0338C11.0883 11.1463 11.2409 11.2095 11.4 11.2095C11.5591 11.2095 11.7117 11.1463 11.8243 11.0338C11.9368 10.9213 12 10.7687 12 10.6095V4.60952C12 4.45039 11.9368 4.29778 11.8243 4.18526C11.7117 4.07274 11.5591 4.00952 11.4 4.00952H5.40001C5.24088 4.00952 5.08826 4.07274 4.97574 4.18526C4.86322 4.29778 4.80001 4.45039 4.80001 4.60952C4.80001 4.76865 4.86322 4.92126 4.97574 5.03379C5.08826 5.14631 5.24088 5.20952 5.40001 5.20952H9.95201L4.17601 10.9855C4.06365 11.098 4.00053 11.2505 4.00053 11.4095C4.00053 11.5685 4.06365 11.721 4.17601 11.8335Z"
          fill="currentColor"
        />
      </g>
      <g v-else-if="iconName === 'pill-close'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711L13.4142 12L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0977 15.6834 17.0977 15.2929 16.7071L12 13.4142L8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071C6.90237 16.3166 6.90237 15.6834 7.29289 15.2929L10.5858 12L7.29289 8.70714C6.90237 8.31661 6.90237 7.68345 7.29289 7.29292C7.68342 6.9024 8.31658 6.9024 8.70711 7.29292L12 10.5858L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289Z"
        />
      </g>
      <g v-else-if="iconName === 'meatballs-menu'">
        <g clip-path="url(#clip0)">
          <path
            d="M7.999 10a2 2 0 100-4 2 2 0 000 4zM1.999 10a2 2 0 100-4 2 2 0 000 4zM13.999 10a2 2 0 100-4 2 2 0 000 4z"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" transform="translate(-.001)" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </g>
      <g v-else-if="iconName === 'lock'">
        <path
          d="M12 4C9.8 4 8 5.8 8 8v1.5c-1.2 1.1-2 2.7-2 4.5 0 3.3 2.7 6 6 6s6-2.7 6-6c0-1.8-.8-3.4-2-4.5V8c0-2.2-1.8-4-4-4zm1 10.7V17h-2v-2.3c-.6-.3-1-1-1-1.7 0-1.1.9-2 2-2s2 .9 2 2c0 .7-.4 1.4-1 1.7zm1-6.3c-.6-.3-1.3-.4-2-.4s-1.4.1-2 .4V8c0-1.1.9-2 2-2s2 .9 2 2v.4z"
        />
      </g>
      <g v-else-if="iconName === 'step-warning'">
        <path
          d="M24 42c9.945 0 18-8.055 18-18S33.945 6 24 6 6 14.055 6 24s8.055 18 18 18zm0-2.17c-8.757 0-15.83-7.092-15.83-15.83 0-8.757 7.073-15.83 15.83-15.83S39.83 15.243 39.83 24c0 8.738-7.073 15.83-15.83 15.83zm1.272-25.41h-2.544l.187 13.771h2.17l.187-13.771zM24 33.73c.926 0 1.684-.758 1.684-1.684 0-.927-.758-1.684-1.684-1.684-.926 0-1.684.758-1.684 1.684 0 .926.758 1.684 1.684 1.684z"
        />
      </g>
      <g v-else-if="iconName === 'circle-checkmark'">
        <path
          d="M24 42c9.945 0 18-8.055 18-18S33.945 6 24 6 6 14.055 6 24s8.055 18 18 18zm0-2.17c-8.757 0-15.83-7.092-15.83-15.83 0-8.757 7.073-15.83 15.83-15.83S39.83 15.243 39.83 24c0 8.738-7.073 15.83-15.83 15.83zm-8.439-15.643l6.362 6.324L33.6 18.873l-1.46-1.46-10.216 10.18-4.94-4.865-1.422 1.46z"
        />
      </g>
      <g v-else-if="iconName === 'like-linear'">
        <path
          d="M11.176 8.265L12 9.466l.824-1.2C13.357 7.488 14.2 7 15.15 7 16.726 7 18 8.28 18 9.739c0 .718-.258 1.605-.743 2.578-.478.96-1.143 1.938-1.87 2.816-.73.88-1.499 1.633-2.17 2.157-.337.263-.63.454-.867.574-.22.112-.33.132-.348.135H12h-.002c-.019-.003-.128-.023-.348-.135a5.616 5.616 0 01-.867-.574c-.671-.524-1.44-1.278-2.17-2.157-.727-.878-1.392-1.855-1.87-2.816C6.258 11.344 6 10.457 6 9.74 6 8.279 7.274 7 8.85 7c.95 0 1.793.49 2.326 1.265z"
          stroke-width="2"
        />
      </g>
      <g v-else-if="iconName === 'locale-globe'">
        <path
          d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0118.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 015.08 16zm2.95-8H5.08a7.987 7.987 0 014.33-3.56A15.65 15.65 0 008.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
        />
      </g>
      <g v-else-if="iconName === 'close'">
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
        />
      </g>
      <g v-else-if="iconName === 'hamburger-menu'">
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
      </g>
      <g v-else-if="iconName === 'unordered-list'">
        <path
          d="M5.5 8.5C6.32843 8.5 7 7.82843 7 7C7 6.17157 6.32843 5.5 5.5 5.5C4.67157 5.5 4 6.17157 4 7C4 7.82843 4.67157 8.5 5.5 8.5Z"
        />
        <path
          d="M5.5 18.5C6.32843 18.5 7 17.8284 7 17C7 16.1716 6.32843 15.5 5.5 15.5C4.67157 15.5 4 16.1716 4 17C4 17.8284 4.67157 18.5 5.5 18.5Z"
        />
        <path
          d="M5.5 13.5C6.32843 13.5 7 12.8284 7 12C7 11.1716 6.32843 10.5 5.5 10.5C4.67157 10.5 4 11.1716 4 12C4 12.8284 4.67157 13.5 5.5 13.5Z"
        />
        <path
          d="M11 8H19C19.552 8 20 7.552 20 7C20 6.448 19.552 6 19 6H11C10.448 6 10 6.448 10 7C10 7.552 10.448 8 11 8Z"
        />
        <path
          d="M19 11H11C10.448 11 10 11.448 10 12C10 12.552 10.448 13 11 13H19C19.552 13 20 12.552 20 12C20 11.448 19.552 11 19 11Z"
        />
        <path
          d="M19 16H11C10.448 16 10 16.448 10 17C10 17.552 10.448 18 11 18H19C19.552 18 20 17.552 20 17C20 16.448 19.552 16 19 16Z"
        />
      </g>
      <g v-else-if="iconName === 'x-logo'">
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#a)">
            <g clip-path="url(#b)">
              <path
                d="M22.435 18.034 31.965 7.2h-2.258l-8.275 9.407-6.61-9.407H7.2l9.994 14.226L7.2 32.785h2.258l8.74-9.934 6.979 9.935H32.8L22.435 18.034Zm-3.093 3.517-1.012-1.417-8.058-11.271h3.47l6.501 9.096 1.013 1.417L29.708 31.2H26.24l-6.897-9.649Z"
              />
            </g>
          </g>
          <defs>
            <clipPath id="a"><path fill="#fff" d="M0 0h40v40H0z" /></clipPath>
            <clipPath id="b">
              <path
                fill="#fff"
                transform="translate(7.2 7.2)"
                d="M0 0h25.6v25.6H0z"
              />
            </clipPath>
          </defs>
        </svg>
      </g>
      <g v-else-if="iconName === 'drop-down-arrow'">
        <path d="M7 10l5 5 5-5H7z" fill="#657281" />
      </g>
      <g v-else-if="iconName === 'bluesky'">
        <path
          :fill="iconFill"
          d="M7.46821 5.77587C9.30257 7.14442 11.2756 9.91928 12 11.4084C12.7245 9.91939 14.6975 7.14439 16.5319 5.77587C17.8555 4.78837 20 4.02431 20 6.45561C20 6.94117 19.7199 10.5346 19.5556 11.118C18.9844 13.1462 16.9033 13.6635 15.0521 13.3504C18.288 13.8977 19.1111 15.7106 17.3334 17.5234C13.9572 20.9664 12.4808 16.6596 12.1023 15.556C12.0329 15.3537 12.0005 15.2591 12 15.3395C11.9995 15.2591 11.9671 15.3537 11.8977 15.556C11.5194 16.6596 10.043 20.9665 6.66658 17.5234C4.88882 15.7106 5.71196 13.8976 8.94786 13.3504C7.09662 13.6635 5.01547 13.1462 4.44444 11.118C4.28013 10.5345 4 6.94112 4 6.45561C4 4.02431 6.14459 4.78837 7.46812 5.77587H7.46821Z"
        />
      </g>
      <!-- placeholder element for proper hydration -->
      <g v-else />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "",
    },
    iconFill: {
      type: String,
      default: "currentColor",
    },
    iconStroke: {
      type: String,
      default: "none",
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    spin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.animate-spin-slow {
  animation: spin-slow 2.5s linear infinite;
}

@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
